<template>
  <div>
    <b-modal
      v-model="modal"
      id="modal-xl"
      size="xl"
      title="Regitstrando Pagamento Fornecedor"
      hide-footer
      @hidden="back_ficha()"
    >
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div
                class="row d-flex justify-content-right align-items-right mb-4"
              >
                <div class="col-md-3 text-left">
                  <label for="">Fornecedor:</label>
                  <input
                    v-model="ficha.nome_fornecedor"
                    type="text"
                    class="form-control text-left"
                  />
                </div>
                <div class="col-md-3 text-left">
                  <label for="">Periodo:</label>
                  <input
                    v-model="ficha.data_inicial"
                    type="date"
                    class="form-control text-left"
                  />
                </div>
                <div class="col-md-3 text-center">
                  <label for="">&emsp;</label>
                  <input
                    v-model="ficha.data_final"
                    type="date"
                    class="form-control text-left"
                  />
                </div>
                <div class="col-md-3 text-right">
                  <br />
                  <b-dropdown
                    id="dropdown-dropup"
                    dropup
                    text="Opções"
                    variant="secondary"
                    class="m-2"
                  >
                    <b-dropdown-item @click="seleciona_rota_conta_paga()"
                      ><i class="fas fa-edit mr-2"></i>Registrar
                      Pagamento</b-dropdown-item
                    >
                    <b-dropdown-item href="#" @click="abrir_opc()"
                      ><i class="fas fa-edit mr-2"></i>Faturamenot de
                      Serviço</b-dropdown-item
                    >
                    <b-dropdown-item @click.prevent="destina_saldo_modal()"
                      ><i class="fas fa-edit mr-2"></i>Destinar Saldo
                      Selecionado</b-dropdown-item
                    >
                    <b-dropdown-item @click="tela_cadastro()"
                      ><i class="fas fa-edit mr-2"></i>ir para
                      Cadastro</b-dropdown-item
                    >
                    <b-dropdown-item @click="seleciona_rota_conta_paga()"
                      ><i class="fas fa-edit mr-2"></i>ir para Contas a
                      Pagar</b-dropdown-item
                    >
                    <b-dropdown-item @click="tela_cadastro()"
                      ><i class="fas fa-edit mr-2"></i>ir para Listar
                      Compras</b-dropdown-item
                    >
                    <b-dropdown-item href="#"
                      ><i class="fas fa-eye mr-2"></i>imprimir Conta
                      Corrente</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <b-modal
                    v-model="show_saldo_modal"
                    id="modal-xl"
                    size="xl"
                    :title="'Vinculando Saldo do Fornecedor : '+Objt_pre_pedido.fornecedor.pes_apelido+' a um Pedido'"  
                    hide-footer
                    @hidden="back()"
                  >
                    <Saldo :vet_adiantamento="vet_adiantamento" :array_pedidos="vet_pedidos3" :Objt_pre_pedido="Objt_pre_pedido" :saldo="soma_adiatamentos" @envia_saldo="envia_saldo" />
                  </b-modal>
                </div>
              </div>
              <b-tabs card>
                <b-tab title="Conta Corrente" active>
                  <div class="row">
                    <div class="col-md-12">
                      <b-table-simple>
                        <b-thead>
                          <b-tr>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th>&nbsp;</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <tr>
                            <td>
                              <b-form-checkbox value="3">
                                Selecionar todos
                              </b-form-checkbox>
                            </td>
                          </tr>
                        </b-tbody>
                      </b-table-simple>
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="vet_corrente"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens_corrente"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox value="3" v-model="row.item.check">
                          </b-form-checkbox>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Contas a Pagar">
                  <div class="row">
                    <div class="col-md-12">
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="vet_contas_a_pagar"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox value="3" v-model="row.item.check">
                          </b-form-checkbox>
                        </template>
                        <template #cell(pago)="row">
                          <span
                            :class="
                              row.item.pago == 1
                                ? 'badge badge-success'
                                : 'badge badge-danger'
                            "
                            >{{
                              row.item.pago == 1
                                ? "Pago"
                                : "Aguardando Pagamento"
                            }}</span
                          >
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="pedidos">
                  <div class="row">
                    <div class="col-md-12">
                      <Label
                        ><b-badge
                          data-name="Total"
                          class="field-status"
                          variant="danger"
                          >{{ "Pedidos com Alguma pendencia" }}</b-badge
                        ></Label
                      >
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="vet_pedidos3"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens_pedidos"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox value="3" v-model="row.item.check">
                          </b-form-checkbox>
                        </template>
                        <template #cell(situacao_id)="row">
                          <!-- <span>{{row.item.recebido == 0 && row.item.faturado == 0 && row.item.estabilizada == 0  ? 'Pedente' : 'sem pendencias' }}</span> -->
                          <span
                            :class="
                              row.item.recebido != 0 &&
                              row.item.faturado != 0 &&
                              row.item.estabilizada != 0
                                ? 'badge badge-success'
                                : 'badge badge-danger'
                            "
                          >
                            {{
                              row.item.recebido == 0 &&
                              row.item.faturado == 0 &&
                              row.item.estabilizada == 0
                                ? "Pedente"
                                : "sem pendencia"
                            }}
                          </span>
                        </template>
                        <template #cell(nome)="row">
                          <span>{{ row.item.fornecedor.pes_apelido }}</span>
                        </template>
                      </b-table>
                    </div>
                    <div class="col-md-12">
                      <Label
                        ><b-badge
                          data-name="Total"
                          class="field-status"
                          variant="success"
                          >{{ "Pedidos Concluidos de : " }}</b-badge
                        ></Label
                      >
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="vet_pedidos2"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens_pedidos"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox value="3" v-model="row.item.check">
                          </b-form-checkbox>
                        </template>
                        <template #cell(situacao_id)="row">
                          <!-- <span>{{row.item.recebido == 0 && row.item.faturado == 0 && row.item.estabilizada == 0  ? 'Pedente' : 'sem pendencia' }}</span> -->
                          <span
                            :class="
                              row.item.recebido != 0 &&
                              row.item.faturado != 0 &&
                              row.item.estabilizada != 0
                                ? 'badge badge-success'
                                : 'badge badge-danger'
                            "
                          >
                            {{
                              row.item.recebido == 0 &&
                              row.item.faturado == 0 &&
                              row.item.estabilizada == 0
                                ? "Pedente"
                                : "sem pendencia"
                            }}
                          </span>
                        </template>
                        <template #cell(nome)="row">
                          <span>{{ row.item.fornecedor.pes_apelido }}</span>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Faturamentos">
                  <div class="row">
                    <div class="col-md-12">
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="vet_faturamentos"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens_faturamento"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox value="3" v-model="row.item.check">
                          </b-form-checkbox>
                        </template>
                        <template #cell(pago)="row">
                          <span
                            :class="
                              row.item.pago == 1
                                ? 'badge badge-success'
                                : 'badge badge-danger'
                            "
                            >{{
                              row.item.pago == 1 ? "Faturado" : "Não Faturado"
                            }}</span
                          >
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Adiantamentos">
                  <div class="row">
                    <div v-if="recarega_tabela" class="col-md-12">
                      <b-table-simple>
                        <b-thead>
                          <b-tr>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th class="b-th">&nbsp;</b-th>
                            <b-th>&nbsp;</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <tr>
                            <td>
                              <b-form-checkbox
                                value="3"
                                v-model="selectAll"
                                @change="selectAllItems(3)"
                              >
                                Selecionar todos
                              </b-form-checkbox>
                            </td>
                          </tr>
                        </b-tbody>
                      </b-table-simple>
                      <b-table
                        head-variant="dark"
                        striped
                        hover
                        :items="vet_adiantamento"
                        responsive
                        id="doc-itens"
                        :fields="fields_itens_adiatamentos"
                      >
                        <template #cell(check)="row">
                          <!-- <span>{{row.item.vecimento}}</span> -->
                          <b-form-checkbox
                            @change="
                              soma_parcial_saldos(row.item, row.item.check)
                            "
                            value="3"
                            v-model="row.item.check"
                          >
                          </b-form-checkbox>
                        </template>
                        <template #cell(situacao)="row">
                          {{
                            row.item.situacao === 0
                              ? "Aguardando Vinculo Manual"
                              : row.item.situacao === 1
                              ? "Vinculo Manual Realizado"
                              : "Situação Não Conhecida"
                          }}
                        </template>
                        <template #cell(valor_pag)="row">
                          {{ formatterCurrBR(row.item.valor_pag) }}
                        </template>
                      </b-table>
                    </div>
                  </div>
                  <div
                    class="row d-flex justify-content-right align-items-right"
                  >
                    <div class="col-md-12 text-right mr-10">
                      <Label
                        ><b-badge
                          data-name="R$"
                          class="soma"
                          variant="success"
                          >{{ formatterCurrBR(soma_adiatamentos) }}</b-badge
                        ></Label
                      >
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Observações">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea
                          name=""
                          id=""
                          cols="30"
                          rows="10"
                          class="form-control text-left"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { http } from "../../../helpers/easyindustriaapi/config";
import Saldo from "./saldoDestinado/saldo.vue";
export default {
  props: {
    abr_modal: { type: Boolean },
    Objeto_pre_pedido: { type: Object },
    vet_pagas: { type: Array },
  },
  components: { Saldo },
  data() {
    return {
      show_saldo_modal: false,
      array_contas_p: [],
      vet_pedidos2: [],
      vet_pedidos3: [],
      ficha: {
        nome_fornecedor: null,
        data_inicial: null,
        data_final: null,
      },
      Objt_pre_pedido: null,
      recarega_tabela: true,
      selectAll: false,
      modal: null,
      vet_fornecedor: [],
      vet_corrente: [],
      vet_adiantamento: [],
      aux_vet_adiatamenotos: [],
      vet_pedidos: [],
      vet_faturamentos: [],
      vet_contas_a_pagar: [],
      fields_itens_faturamento: [
        // {
        //   label: "",
        //   key: "check",
        //   tdClass: "text-center",
        //   thClass: "text-center info",
        // },
        {
          label: "Nome",
          key: "cliente_nome",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Numero",
          key: "num_doc",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "data_fatura",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Situação",
          key: "pago",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "success",
        },
      ],

      fields_itens_pedidos: [
        {
          label: "",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center info",
        },
        {
          label: "Nome",
          key: "nome",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Número",
          key: "nnf",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "data",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Situação",
          key: "situacao_id",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Faturamento",
          key: "tipo_id",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "success",
        },

        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields_itens_adiatamentos: [
        {
          label: "",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center info",
        },
        {
          label: "Número",
          key: "id",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "data",
          tdClass: "text-center ",
          thClass: "text-center",
        },
        {
          label: "Descrição",
          key: "desc",
          tdClass: "text-center ",
          thClass: "text-center",
          variant: "info",
        },
        {
          label: "Situação",
          key: "situacao",
          tdClass: "text-center ",
          thClass: "text-center",
          variant: "danger",
        },
        {
          label: "Valor",
          key: "valor_pag",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "success",
        },

        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields_itens_corrente: [
        {
          label: "",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "data",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Saldo",
          key: "ccorrente_limite",
          tdClass: "text-center",
          thClass: "text-center",
        },

        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      fields_itens: [
        {
          label: "Número",
          key: "id",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Fatura",
          key: "data_fatura",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Descrição",
          key: "obs",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "PC",
        //   key: "cod_cupom",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Situação",
          key: "pago",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Desconto",
        //   key: "desconto",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // {
        //   label: "Total",
        //   key: "tot",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      soma_adiatamentos: 0,
    };
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.modal = this.abr_modal;
    this.Objt_pre_pedido = this.Objeto_pre_pedido;
    this.ficha.nome_fornecedor = this.Objt_pre_pedido.fornecedor.pes_apelido;
    this.array_contas_p = this.vet_pagas;
    // this.vet_faturamentos = this.vet_pagas
    // this.vet_contas_a_pagar = this.vet_pagas
  },
  mounted() {
    this.get_pagamentos_antecipaddos();
    // tenho que fazer a pesquisa pelo id  do fornecedor
    //this.get_contas_a_pagar();
    this.get_pedidos();
    this.get_conta_corrente();
    // console.log("Mostrando Objeto de Prepedido que tem FOrnecedor")
    // console.log(this.Objt_pre_pedido.fornecedor.pes_apelido)
    // console.log("Mostrando vetor de Constas a pagar Faturamento de prepedidos")
    // console.log(this.vet_faturamentos)
    // console.log("mostando objeto Pre_pedido")
    // console.log(this.Objt_pre_pedido)

    this.preenchche_fat_contas_pagas();
    console.log("Mostrando valores do vet adiantentos");
    console.log(this.vet_adiantamento);
  },
  methods: {
  // usado para aatualiaz o saldo que foi vinculado a um pedido 
  async atualiza_saldo_forncedor(objeto_saldo){
    // tenho que receber qui o valor que foi utilizado na vinculaçao do saldo.
    try {
        let response = await http.put(
          "/regPag/" +
          objeto_saldo.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
            objeto_saldo
        );
        if (response) {
          //this.offLoader();
          if (response.status === 200) {
            this.makeToast("success", "Registro alterado");
            // this.getData();
            // this.editando = false;
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
  },
  async envia_saldo(obejeto_salddo_pre, objeto_adant){
       console.log("Mostrando saldo utilizado")
       console.log(objeto_adant)
       console.log("Estou no Objjeto enviaar saldo")
       console.log(obejeto_salddo_pre)
       try {
        let response = await http.put(
          "/pre-pedido/updt/" +
          obejeto_salddo_pre.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
            obejeto_salddo_pre
        );
        if (response) {
          //this.offLoader();
          if (response.status === 200) {
            this.makeToast("success", "Registro alterado");
            // this.getData();
            // this.editando = false;
            this.atualiza_saldo_forncedor(objeto_adant)
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    tela_cadastro() {
      console.log("estou na tela de cadastro");
      this.back_ficha();
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    back() {
      this.show_saldo_modal = false;
    },
    destina_saldo_modal(objeto) {
      if(this.vet_adiantamento.length > 0){
        this.show_saldo_modal = true;
      }else {
        this.makeToast("danger","Não tem valores adiantados a esse fornecedor a serem vinculados ao pedido")
      }
     
      console.log("estou em destina saldo modal");
      console.log(objeto);
      // if()
      // this.vet_adiantamento.length == 0
      //   ? this.makeToast(
      //       "danger",
      //       "Não existe valores Adiantados para esse fornecedor"
      //     )
      //   : "";
    },
    seleciona_rota_conta_paga(objeto) {
      console.log(objeto);
      this.$router.push("/financeiro/contasPagar");
    },
    preenchche_fat_contas_pagas() {
      console.log("EStou no metod fata COnsta paga");
      console.log(this.Objt_pre_pedido.fornecedor_id);
      console.log(this.array_contas_p);
      let resultados = this.array_contas_p.filter(
        (item) => item.cliente_cod == this.Objt_pre_pedido.fornecedor_id
      );
      this.vet_faturamentos = resultados;
      this.vet_contas_a_pagar = resultados;
    },
    // aqui é feita a soma toal de
    back_ficha() {
      this.$emit("back_ficha");
    },
    soma_parcial_saldos(objeto, check) {
      console.log(objeto);
      if (check == 3) {
        this.soma_adiatamentos =
          this.soma_adiatamentos + parseFloat(objeto.valor_pag);
      } else {
        this.soma_adiatamentos =
          this.soma_adiatamentos - parseFloat(objeto.valor_pag);
      }
    },
    selectAllItems() {
      this.aux_vet_adiatamenotos = this.vet_adiantamento;
      // var tam = this.aux_vet_adiatamenotos.length;
      // var cont = 0
      // this.vet_adiatamenotos = []
      this.recarega_tabela = false;
      this.soma_adiatamentos = 0;
      // Percorre o vetor e define o estado 'check' de todos os itens com base no estado do checkbox "Selecionar Todos"
      for (let item of this.aux_vet_adiatamenotos) {
        item.check = this.selectAll;
        if (this.selectAll == 3) {
          this.soma_adiatamentos =
            this.soma_adiatamentos + parseFloat(item.valor_pag);
        }
      }
      if (this.selectAll == false) {
        this.soma_adiatamentos = 0;
      }
      setTimeout(() => {
        this.recarega_tabela = true;
      }, 1);
      console.log(this.soma_adiatamentos);
    },
    async get_pagamentos_antecipaddos() {
      try {
        let response = await http.get(
          "/regPag?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.vet_adiantamento = response.data;
          console.log("ADIANTAMENTOS");
          console.log(this.vet_adiantamento);
          //console.log(this.vet_adiantamento);
          const resultados = this.vet_adiantamento.filter(
            (item) => item.pessoa_id == this.Objt_pre_pedido.fornecedor_id
          );
          this.vet_adiantamento = [];
          this.vet_adiantamento = resultados;
          console.log("Mostrando resultados Adiantamentos");
          console.log(resultados);
        } else {
          this.vet_adiantamento = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_conta_corrente() {
      try {
        let response = await http.get(
          "/corrente?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.vet_corrente = response.data;
          console.log(this.vet_corrente);
        } else {
          this.vet_corrente = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    // async get_contas_a_pagar() {
    //   try {
    //     let response = await http.get(
    //       "/pagas?empresa_id=" + this.currentEmpresa.id
    //     );
    //     if (response.status === 200) {
    //       this.vet_contas_a_pagar = response.data;
    //       //console.log(this.vet_contas_a_pagar);
    //     } else {
    //       this.vet_contas_a_pagar = []; // zera o array;
    //       this.makeToast("danger", "Erro ao carregar lista");
    //     }
    //   } catch (error) {
    //     if (error.response) {
    //       if (error.response.status === 404) {
    //         this.makeToast("danger", "Destino URL não encontrado!");
    //       } else {
    //         this.makeToast("danger", error.response);
    //       }
    //     }
    //   }
    // },
    filtrar_vet_pedidos_confirmados(vetor) {
      console.log("estou na filtragem de vet pedidoss");
      console.log(vetor);
      //  em penenciaa
      const resultados = vetor.filter(
        (item) =>
          item.faturado == 1 && item.recebido == 1 && item.estabilizada == 1
      );
      //com penenciaa
      const resultados2 = vetor.filter(
        (item) =>
          item.faturado != 1 || item.recebido != 1 || item.estabilizada != 1
      );
      console.log(resultados);
      console.log(resultados2);
      this.vet_pedidos2 = resultados;
      this.vet_pedidos3 = resultados2;
    },
    async get_pedidos() {
      try {
        let response = await http.get(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.vet_pedidos = response.data;
          const resultados = this.vet_pedidos.filter(
            (item) => item.fornecedor_id == this.Objt_pre_pedido.fornecedor_id
          );
          //this.vet_faturamentos = response.data;
          //console.log(this.vet_pedidos);
          this.filtrar_vet_pedidos_confirmados(resultados);
        } else {
          this.vet_pedidos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    fechar_financeiro() {
      this.$emit("fechar_financeiro");
    },
  },
};
</script>
<style scoped>
.soma {
  width: 100px;
  height: 28px;
  font-size: 20px;
}
.custom-table-header th {
  background-color: #3498db; /* Substitua esta cor pelo código da cor desejada */
  color: #ffffff; /* Cor do texto no cabeçalho */
}
</style>