import { render, staticRenderFns } from "./estabelecer1.vue?vue&type=template&id=2550ff68&scoped=true&"
import script from "./estabelecer1.vue?vue&type=script&lang=js&"
export * from "./estabelecer1.vue?vue&type=script&lang=js&"
import style0 from "./estabelecer1.vue?vue&type=style&index=0&id=2550ff68&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2550ff68",
  null
  
)

export default component.exports