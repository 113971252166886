<script>
import Detail from "./detail";
import fatura from "./faturar1.vue";
import estabelecer from "./estabelecer1.vue";
import recebida from "./recebimento.vue";
import verFat from "./verFatura.vue";
import Vue from "vue";
import VueDirectiveTooltip from "vue-directive-tooltip";
import Ficha from "./fichafinanceira.vue";
Vue.use(VueDirectiveTooltip, {
  delay: 0,
});

export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    listPreCompra: { type: Array, required: true },
    hide: { type: Boolean, required: true },
    plano_contas: { type: Array },
    fornecedores_pessoa: { type: Array },
    vet_pagas:{type: Array}
  },
  components: { Detail, fatura, estabelecer, recebida, verFat,Ficha },
  data() {
    return {
      Objeto_pre_pedido:null,
      ficha_modal:false,
      fornecedores_array: [],
      titleBody: "Produto Item",
      currentTabelas: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          label: "ID",
          key: "id",
          sortable: true,
          tdClass: "text-left",
          thClass: "text-center",
        },
        {
          label: "Nnf",
          key: "nnf",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Fornecedor",
          key: "fornecedor_id",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },

        {
          label: "Situação",
          key: "sit",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        // {
        //   label: "Recebimento",
        //   key: "recebido",
        //   sortable: true,
        //   thClass: "text-center",
        //   tdClass: "text-center",
        // },
        // {
        //   label: "Estabilização",
        //   key: "estabilizada",
        //   sortable: true,
        //   thClass: "text-center",
        //   tdClass: "text-center",
        // },
        // {
        //   label: "Faturamento",
        //   key: "faturado",
        //   sortable: true,
        //   thClass: "text-center",
        //   tdClass: "text-center",
        // },

        //{ label: "ID_Empresa", key: "empresa_id", sortable: false, thClass: 'text-center' },
        // { label: 'Preço', key: 'preco', sortable: false, tdClass: 'text-right', thClass: 'text-center',
        //   formatter: "formatCurrency"},
        {
          label: "Data",
          key: "created_at",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      abri_modal_fatura: false,
      objeto_fatura: {},
      abri_modal_estabilizar: false,
      objeto_estabilizar: {},
      abri_modal_recebimento: false,
      objeto_recebimento: {},
      abri_modal_ver_fat: false,
      objeto_ver_fat: {},
    };
  },
  created() {
    this.fornecedores_array = this.fornecedores_pessoa;
  },
  computed: {
    /**
     * Total no. of records
     */

    rows() {
      return this.listPreCompra.length ? this.listPreCompra.length : 0;
    },
    clonefoot() {
      return this.listPreCompra.length || this.listPreCompra.length > 10
        ? true
        : false;
    },
  },
  mounted() {
    this.$emit("newTitle", this.titleBody);

    console.log("estou no list Pcontas");
    //  console.log(this.fornecedores_pessoa)
    console.log(this.plano_contas);
  },

  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // eslint-disable-next-line no-unused-vars
    formatCurrency(value, key, item) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    setFicha(objeto){
        console.log(objeto)
        this.Objeto_pre_pedido = objeto
        this.ficha_modal = true
    },
    back_ficha(){
      this.ficha_modal = false
    },
    get_planos_contas() {},
    setFaturar(objeto) {
      // serve para abrir o modal de fatura  quando clicar no botaão Fatuar
      // que esta no componente list;
      this.abri_modal_fatura = true;
      this.objeto_fatura = objeto;
      //console.log(objeto);
    },
    setTabelas(value) {
      this.currentTabelas = value;
      this.$refs["modal-tabelas"].show();
    },
    setEdit(value) {
      this.setTabelas(value);
      this.$emit("edit", value);
    },
    setDelete(value) {
      this.$emit("doDelete", value);
    },
    back() {
      this.abri_modal_fatura = false;
      this.abri_modal_estabilizar = false;
      this.abri_modal_recebimento = false;
      this.abri_modal_ver_fat = false;
    },
    recebida(objeto) {
      // this.$emit("recebida", objeto);
      this.abri_modal_recebimento = false;
      this.$emit("doPut", objeto);
    },
    setEstabilizar(value) {
      (this.abri_modal_estabilizar = true), 
      (this.objeto_estabilizar = value);
      console.log("Monstrando valor do se estabilizar")
      console.log(value)
    },
    estabelecida(objeto) {
      this.abri_modal_estabilizar = false
      this.$emit("estabelecida", objeto);
    },
    faturando(objeto) {
      this.abri_modal_fatura =false
      console.log();
      objeto.faturado = 1;
      this.$emit("doPut", objeto);
    },
    setRecebimento(objeto) {
      this.abri_modal_recebimento = true;
      this.objeto_recebimento = objeto;
    },
    ver_fatura(objeto) {
      console.log(objeto);
      (this.abri_modal_ver_fat = true), (this.objeto_ver_fat = objeto);
    },
    formatarData(data) {
      // Criando um objeto Date a partir da string de data fornecida
      const dataObj = new Date(data);

      // Obtendo o dia, mês e ano da data
      const dia = this.pad(dataObj.getUTCDate()); // O método getUTCDate() retorna o dia (1 a 31) em UTC
      const mes = this.pad(dataObj.getUTCMonth() + 1); // O método getUTCMonth() retorna o mês (0 a 11) em UTC
      const ano = dataObj.getUTCFullYear(); // O método getUTCFullYear() retorna o ano em UTC

      // Obtendo a hora e minuto da data
      const hora = this.pad(dataObj.getUTCHours()); // O método getUTCHours() retorna a hora (0 a 23) em UTC
      const minuto = this.pad(dataObj.getUTCMinutes()); // O método getUTCMinutes() retorna o minuto (0 a 59) em UTC

      // Retornando a data e hora formatadas
      return `${dia}-${mes}-${ano} ${hora}:${minuto}`;
    },

    // Função auxiliar para adicionar um zero à esquerda se o número for menor que 10
    pad(numero) {
      return numero < 10 ? "0" + numero : numero;
    },
  },
};
</script>

<template>
  <div class="card-body" v-if="hide">
    <div class="row mt-4">
      <!-- Start Limit -->
      <div class="col-sm-12 col-md-6">
        <div id="tabelas-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Exibir&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
      <!-- End Limit -->
      <!-- Start Search -->
      <div class="col-sm-12 col-md-6">
        <div id="produto-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Pesquisar..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Start Table -->
    <div class="row mt-4">
      <div v-if="hide" class="table-responsive mb-0">
        <!--  responsive="sm" -->
        <b-table
          :items="listPreCompra"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :hover="true"
          :foot-clone="clonefoot"
        >
          <template #cell(acoes)="row">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown v-bind:id="'dropdown-' + row.item.id" class="m-md-2">
              <template #button-content>
                <i data-v-6289eca4="" class="bx ri-menu-line"></i>
              </template>
              <!-- <b-dropdown-item v-b-modal.modal-tabelas @click="setTabelas(row.item)"><i class="bx ri-eraser-fill"></i> Visualizar</b-dropdown-item> -->
              <b-dropdown-item
                @click="setFicha(row.item)"
                ><i class="bx ri-eye-2-line"></i> Ficha Finaceira</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.faturado === 0"
                @click="setEdit(row.item)"
                ><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.estabilizada === 0"
                @click="setEstabilizar(row.item)"
                ><i class="bx ri-edit-2-line"></i> Estabilizar</b-dropdown-item
              >

              <b-dropdown-item
                v-if="row.item.faturado === 0"
                @click="setFaturar(row.item)"
                ><i class="bx ri-edit-2-line"></i>
                {{ row.item.faturado == 0 ? "Faturar" : "" }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.faturado === 1"
                @click="ver_fatura(row.item)"
                ><i class="bx ri-edit-2-line"></i>
                {{
                  row.item.faturado == 1 ? "Ver Fatura" : ""
                }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="row.item.recebido === 0"
                @click="setRecebimento(row.item)"
                ><i class="bx ri-edit-2-line"></i>
                {{
                  row.item.recebido == 0 ? "Confirmar Recebimento" : ""
                }}</b-dropdown-item
              >

              <b-dropdown-item @click="setDelete(row.item)"
                ><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item
              >
            </b-dropdown>
          </template>

          <template #cell(sit)="row">
            <p>
              <i
                v-tooltip.bootom="'recebido'"
                :class="
                  row.item.recebido == 1
                    ? 'fas fa-truck fa-lg text-success mr-2'
                    : 'fas fa-truck fa-lg text-danger mr-2'
                "
              ></i>
              <i
                v-tooltip.bootom="'estabilizar'"
                class="fas fa-clipboard-check fa-lg mr-2"
                :class="
                  row.item.estabilizada == 1 ? 'text-success' : 'text-danger'
                "
              ></i>
              <i
                v-tooltip.bootom="'faturar'"
                :class="
                  row.item.faturado == 1
                    ? 'fas fa-money-bill fa-lg text-success mr-2 '
                    : 'fas fa-money-bill fa-lg text-danger'
                "
              ></i>
            </p>
            <!-- {{ row.item.estabilizada == 0 ? "" : "" }}  -->
            <!-- <p><i :class="row.item.faturado == 1 ? 'fas fa-money-bill fa-lg text-success ' : 'fas fa-money-bill fa-lg text-danger' "></i></p> -->
            <!-- {{ row.item.faturado == 0 ? "" : "" }}  -->
            <p></p>
            <!-- {{ row.item.recebido }} -->
          </template>
          <template #cell(estabilizada)="row">
            <!-- <b-badge
              data-name="credito"
              class="field-status"
              v-bind:variant="row.item.estabilizada == 1 ? 'success' : 'danger'"
              v-tooltip.bottom="
                row.item.estabilizada == 1 ? 'Estabelecida' : 'Não Estabelecida'
              "
            >
              <p><i class="fas fa-balance-scale tamanho_fonte"></i></p>
              {{ row.item.estabilizada == 0 ? "" : "" }}
              {{ row.item.estabilizada == 0 ? "Estabilizada" : "Establizada" }}
            </b-badge> -->
            <p>
              <i
                class="fas fa-clipboard-check fa-lg"
                :class="
                  row.item.estabilizada == 1 ? 'text-success' : 'text-danger'
                "
              ></i>
            </p>
            {{ row.item.estabilizada == 0 ? "" : "" }}
          </template>
          <template #cell(faturado)="row">
            <!-- <b-badge
              data-name="credito"
              class="field-status"
              v-bind:variant="row.item.faturado == 1 ? 'success' : 'danger'"
              v-tooltip.bottom="
                row.item.faturado == 1 ? 'Faturado' : 'Não Faturado'
              "
            >
              <p><i class="fas fa-money-bill mr-1 tamanho_fonte"></i></p>
              {{ row.item.faturado == 0 ? "" : "" }}
              
            </b-badge> -->
            <p>
              <i
                :class="
                  row.item.faturado == 1
                    ? 'fas fa-money-bill fa-lg text-success '
                    : 'fas fa-money-bill fa-lg text-danger'
                "
              ></i>
            </p>
            {{ row.item.faturado == 0 ? "" : "" }}
          </template>
          <template #cell(recebido)="row">
            <!-- <b-badge
              data-name="credito"
              class="field-status"
              v-bind:variant="row.item.recebido == 1 ? '' : 'danger'"
              v-tooltip.bottom="
                row.item.recebido == 1 ? 'Recebido' : 'Não Recebido'
              "
            > -->
            <p>
              <i
                :class="
                  row.item.recebido == 1
                    ? 'fas fa-truck fa-lg text-success'
                    : 'fas fa-truck fa-lg text-danger'
                "
              ></i>
            </p>
            {{ row.item.recebido }}
            <!-- {{ row.item.recebido == 0 ? "Recebido" : "Recebido" }} -->
            <!-- </b-badge> -->
          </template>
          <template #cell(created_at)="row">
            <div>
              <span
                ><b-badge
                  data-name="credito"
                  class="field-status fonte_Size"
                  variant="secondary"
                  v-tooltip.bottom="'Data de Criação'"
                  >{{ formatarData(row.item.created_at) }}
                  </b-badge
                ></span
              >
            </div>
          </template>
          <template #cell(nnf)="row">
            <div>
              {{ row.item.nnf }}
            </div>
          </template>
          <template #cell(id)="row">
            <!-- <div>
              <span
                ><b-badge
                  data-name="credito"
                  class="field-status fonte_Size"
                  variant="dark"
                  v-tooltip.bottom="'Id do Pedido'"
                  ></b-badge
                ></span
              >
            </div> -->
            {{ row.item.id }}
          </template>
          <template #cell(fornecedor_id)="row">
            <div>
              <!-- <span
                ><b-badge
                  data-name="credito"
                  class="field-status fonte_Size"
                  variant="secondary"
                  v-tooltip.bottom="'Id do Pedido'"
                  ></b-badge
                ></span
              > -->
              {{
                row.item.fornecedor_id == row.item.fornecedor.id
                  ? row.item.fornecedor.pes_apelido
                  : ""
              }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <!-- End Table -->
    <!-- Start Pagination -->
    <div v-if="hide" class="row mt-4">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Pagination -->
    <!-- Start Modal Produto -->
    <b-modal
      v-if="hide"
      hide-footer
      id="modal-tabelas"
      ref="modal-tabelas"
      :title="'Detalhes da tabelas'"
      title-class="font-18"
    >
      <Detail :tabelas="currentTabelas" />
    </b-modal>
    <div v-if="abri_modal_fatura" class="row">
      <div class="col-md-12">
        <fatura
          :abr_fatura="abri_modal_fatura"
          :inicial_obj1="objeto_fatura"
          :p_contas2="plano_contas"
          @back="back"
          @faturando="faturando"
        ></fatura>
      </div>
    </div>
    <div v-if="abri_modal_estabilizar" class="row">
      <div class="col-md-12">
        <estabelecer
          :modal_estabelecer="abri_modal_estabilizar"
          :item_obj1="objeto_estabilizar"
          @back="back"
          @estabelecida="estabelecida"
        />
      </div>
    </div>

    <div v-if="abri_modal_recebimento" class="row">
      <div class="col-md-12">
        <recebida
          :modal_recebimento="abri_modal_recebimento"
          :item_obj1="objeto_recebimento"
          @back="back"
          @recebida="recebida"
        />
      </div>
    </div>
    <div v-if="abri_modal_ver_fat" class="row">
      <div class="col-md-12">
        <verFat
          :modal_ver_fat="abri_modal_ver_fat"
          :item_obj1="objeto_ver_fat"
          @back="back"
          @recebida="recebida"
        />
      </div>
    </div>
    <div v-if="ficha_modal" class="row">
      <div class="col-md-12">
        <Ficha
          :abr_modal="ficha_modal"
          :vet_pagas="vet_pagas"
          @back_ficha="back_ficha"
          :Objeto_pre_pedido= "Objeto_pre_pedido"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-badge
          data-name="credito"
          class="mr-1 larg"
          variant="success"
          v-tooltip.right="'- Está tudo certo '"
          >{{ "Verde" }}</b-badge
        >
        <p style="display: inline-block; vertical-align: top">
          - Está tudo certo | Recebido | Estabelecido | Faturado
        </p>
      </div>

      <div class="col-md-12">
        <b-badge
          data-name="credito"
          class="mr-1 larg"
          variant="danger"
          v-tooltip.right="'- Pedido Não Recebido'"
          >{{ "Vermehlo" }}</b-badge
        >
        <p style="display: inline-block; vertical-align: top">
          - Pedido Não Recebido | Não Estabelizada | Não Faturado
        </p>
      </div>
    </div>
    <!-- End Modal Produto -->
  </div>
</template>
<style scoped>
.larg {
  margin: 0;
  display: inline-block;
}
p {
  margin: 0;
  padding: 0;
}

.fonte_Size {
  font-size: 10px;
}
.tamanho_fonte {
  font-size: 18px;
  width: 40px;
  height: 10px;
}
v-tooltip {
  background-color: black;
}
</style>